import React, { useState } from 'react';
import '../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../components/topbar/Topbar';
import Input from '../../../components/input/Input';
import Navbar from '../../../components/navbar/Navbar';
import Button from '../../../components/button/Button';
import Swal from 'sweetalert2';
import { db } from '../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore'
import { useUserAuth } from '../../../context/UserAuthContext';


function Form() {

const [servicio, setServicio] = useState('');
const [Infraestructura1, setInfraestructura1] = useState('');
const [Infraestructura2, setInfraestructura2] = useState('');
const [Infraestructura3, setInfraestructura3] = useState('');
const [Infraestructura4, setInfraestructura4] = useState('');
const [Infraestructura5, setInfraestructura5] = useState('');
const [documentos1, setDocumentos1] = useState('');
const [documentos2, setDocumentos2] = useState('');
const [documentos3, setDocumentos3] = useState('');
const [documentos4, setDocumentos4] = useState('');
const [cuidado1, setCuidado1] = useState('');
const [cuidado2, setCuidado2] = useState('');
const [cuidado3, setCuidado3] = useState('');
const [cuidado4, setCuidado4] = useState('');
const [cuidado5, setCuidado5] = useState('');
const [cuidado6, setCuidado6] = useState('');
const [personal1, setPersonal1] = useState('');
const [personal2, setPersonal2] = useState('');
const [personal3, setPersonal3] = useState('');
const [personal4, setPersonal4] = useState('');
const [personal5, setPersonal5] = useState('');
const [personal6, setPersonal6] = useState('');
const [prevencion1, setPrevencion1] = useState('');
const [prevencion2, setPrevencion2] = useState('');
const [prevencion3, setPrevencion3] = useState('');
const [prevencion4, setPrevencion4] = useState('');
const [prevencion5, setPrevencion5] = useState('');
const [prevencion6, setPrevencion6] = useState('');
const [prevencion7, setPrevencion7] = useState('');
const [prevencion8, setPrevencion8] = useState('');
const [humanizacion1, setHumanizacion1] = useState('');
const [humanizacion2, sethumanizacion2] = useState('');
const [humanizacion3, setHumanizacion3] = useState('');
const [humanizacion4, setHumanizacion4] = useState('');
const [humanizacion5, setHumanizacion5] = useState('');
const [humanizacion6, setHumanizacion6] = useState('');
const [humanizacion7, setHumanizacion7] = useState('');
const [humanizacion8, setHumanizacion8] = useState('');
const [humanizacion9, setHumanizacion9] = useState('');

const navigate = useNavigate();
const { user } = useUserAuth();
const date = new Date();
const formCollection = collection(db, "Ronda de seguridad");

const handleSubmit = async (e) => {
  e.preventDefault();
  await addDoc(formCollection, {
              user: user.email,
              servicio: servicio,
              Infraestructura1: Infraestructura1,
              Infraestructura2: Infraestructura2,
              Infraestructura3: Infraestructura3,
              Infraestructura4: Infraestructura4,
              Infraestructura5: Infraestructura5,
              documentos1: documentos1,
              documentos2: documentos2,
              documentos3: documentos3,
              documentos4: documentos4,
              cuidado1: cuidado1,
              cuidado2: cuidado2,
              cuidado3: cuidado3,
              cuidado4: cuidado4,
              cuidado5: cuidado5,
              cuidado6: cuidado6,
              personal1: personal1,
              personal2: personal2,
              personal3: personal3,
              personal4: personal4,
              personal5: personal5,
              personal6: personal6,
              prevencion1: prevencion1,
              prevencion2: prevencion2,
              prevencion3: prevencion3,
              prevencion4: prevencion4,
              prevencion5: prevencion5,
              prevencion6: prevencion6,
              prevencion7: prevencion7,
              prevencion8: prevencion8,
              humanizacion1: humanizacion1,
              humanizacion2: humanizacion2,
              humanizacion3: humanizacion3,
              humanizacion4: humanizacion4,
              humanizacion5: humanizacion5,
              humanizacion6: humanizacion6,
              humanizacion7: humanizacion7,
              humanizacion8: humanizacion8,
              date: date,
  })
  .then(()=> {
    Swal.fire(
      '¡Buen trabajo!',
      'El forumlario ha sido subido exitosamente',
      'success'
    )
    .then(()=> {
      navigate('/feed');
    })
  });
}

  return (
    <>
    <Topbar
      user='Clínica Materno Infantil'
    />
    <div className="form">
      <h2>Ronda de seguridad del paciente</h2>
      <div className="form__container">
        <small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus iste.</small>
        <form action="" onSubmit={handleSubmit}>
          <br />
          <label>Servicio</label>
            <select className='form__input' onChange={(e)=>setServicio(e.target.value)}>
              <option hidden defaultValue>Elige el servicio</option>
              <option value='Hospitalización'>Hospitalización</option>
              <option value='Unidad de cuidados intensivos'>Unidad de cuidados intensivos</option>
              <option value='Urgencias'>Urgencias</option>
            </select>
            <h5>Infraestructura física</h5>
            <br />
            <label htmlFor="">Continuidad de pisos y paredes</label>
            <select className='form__input' onChange={(e)=>setInfraestructura1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Existe ventilación natural o artificial</label>
            <select className='form__input' onChange={(e)=>setInfraestructura2(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Existe iluminación natural o artificial</label>
            <select className='form__input' onChange={(e)=>setInfraestructura3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Cuenta con dispositivos/herramientas para privacidad del usuario</label>
            <select className='form__input' onChange={(e)=>setInfraestructura4(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <Input
              label='Observaciones de documentos'
              placeholder='Observaciones'
              onChange={(e)=>setInfraestructura5(e.target.value)}
            />
            <h5>Documentos</h5>
            <br />
            <label htmlFor="">Se utilizan formatos vigentes</label>
            <select className='form__input' onChange={(e)=>setDocumentos1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Diligenciamiento completo de consentimiento informado y entendimiento por parte del usuario y tutor legal</label>
            <select className='form__input' onChange={(e)=>setDocumentos2(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Se realiza registro completo de los formatos de historia clínica</label>
            <select className='form__input' onChange={(e)=>setDocumentos3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <Input
              label='Observaciones de documentos'
              placeholder='Observaciones'
              onChange={(e)=>setDocumentos4(e.target.value)}
            />
            <h5>Cuidado del paciente enfoque riesgo</h5>
            <br />
            <label htmlFor=""  onChange={(e)=>setCuidado1(e.target.value)}>Se clasificó riesgo de la gestante, puerpera o recién nacido</label>
            <select className='form__input'>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Trabajador identificó los medios de reporte de incidentes y eventos adversos</label>
            <select className='form__input' onChange={(e)=>setCuidado2(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">El entorno del paciente es seguro(barandas arriba, escalerrillas, etc)</label>
            <select className='form__input' onChange={(e)=>setCuidado3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Identificación del paciente en manilla, tablero, identificador, HC, etc</label>
            <select className='form__input' onChange={(e)=>setCuidado4(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Se diligenció escala de riesgo de caidas</label>
            <select className='form__input' onChange={(e)=>setCuidado5(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <Input
              label='Observaciones de documentos'
              placeholder='Observaciones'
              onChange={(e)=>setCuidado6(e.target.value)}
            />
            <h5>Personal</h5>
            <br />
            <label htmlFor="">Personal completo según programación de turnos</label>
            <select className='form__input' onChange={(e)=>setPersonal1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Entrega de turno a través de revisión de libro</label>
            <select className='form__input' onChange={(e)=>setPersonal2(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Servicio cuenta con equipo necesario (tarjeta de roles, actividades de atención, código rojo y azul)</label>
            <select className='form__input' onChange={(e)=>setPersonal3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Conoce política de seguridad del paciente</label>
            <select className='form__input' onChange={(e)=>setPersonal4(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Identifica medios de reporte de eventos de seguridad</label>
            <select className='form__input' onChange={(e)=>setPersonal5(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <Input
              label='Observaciones de documentos'
              placeholder='Observaciones'
              onChange={(e)=>setPersonal6(e.target.value)}
            />
            <h5>Medidas de prevención de infecciones</h5>
            <br />
            <label htmlFor="">Personal cumple con protocolo higiene de mano.</label>
            <select className='form__input' onChange={(e)=>setPrevencion1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Disponibilidad de elementos para lavado e higienización de manos.</label>
            <select className='form__input' onChange={(e)=>setPrevencion2(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Indicadores que garantizan esterilidad de inssumos y dispositivos médicos</label>
            <select className='form__input' onChange={(e)=>setPrevencion3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Rotulación de mezclas y cambios de equipos de accesos periférico y central</label>
            <select className='form__input' onChange={(e)=>setPrevencion4(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Limpieza y desinfección de áreas y superficies</label>
            <select className='form__input' onChange={(e)=>setPrevencion5(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Segregación adecuada de residuos en canecas y guardianes</label>
            <select className='form__input' onChange={(e)=>setPrevencion6(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Se cumple con EPP y medidas de aislamiento</label>
            <select className='form__input' onChange={(e)=>setPrevencion7(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <Input
              label='Observaciones de documentos'
              placeholder='Observaciones'
              onChange={(e)=>setPrevencion8(e.target.value)}
            />

            <h5>Humanización del servicio</h5>
            <br />
            <label htmlFor="">Colaboradores saludan y se presentan al iniciar atención</label>
            <select className='form__input' onChange={(e)=>setHumanizacion1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Pacientes y familiares reciben info. por parte del equipo sobre la atención recibida</label>
            <select className='form__input' onChange={(e)=>sethumanizacion2(e.target.value)} >
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Pacientes refieren satisfacción por el trato digno y respetuoso del personal médico</label>
            <select className='form__input' onChange={(e)=>setHumanizacion3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Pacientes refieren satisfacción por el trato digno y respetuoso del personal de enfermería</label>
            <select className='form__input' onChange={(e)=>setHumanizacion4(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Pacientes han recibido información/asesoría en lactancia materna</label>
            <select className='form__input' onChange={(e)=>setHumanizacion5(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">Parto con acompañamiento familiar</label>
            <select className='form__input' onChange={(e)=>setHumanizacion6(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">En general, busca percepción de atención recibida durante toda la estancia</label>
            <select className='form__input' onChange={(e)=>setHumanizacion7(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label htmlFor="">El porcentaje de satisfacción para el momento es mayor al 90%</label>
            <select className='form__input' onChange={(e)=>setHumanizacion8(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value='Cumple'>Cumple</option>
              <option value='No cumple'>No cumple</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <Input
              label='Observaciones de documentos'
              placeholder='Observaciones'
              onChange={(e)=>setHumanizacion9(e.target.value)}
            />
            <div className="form__btn">
              <Button
                title='Enviar'
              />
            </div>
        </form>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Form