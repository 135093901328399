import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';

const ProtectedRoute = ({ children }) => {
    let { user } = useUserAuth();
    const navigate = useNavigate();
    if(!user) {
       return navigate('/');
    }
    return children;
};

export default ProtectedRoute;