import React, { useState } from 'react';
import '../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../components/topbar/Topbar';
import Navbar from '../../../components/navbar/Navbar';
import Button from '../../../components/button/Button';
import Swal from 'sweetalert2';
import { db } from '../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore'
import { useUserAuth } from '../../../context/UserAuthContext';


function Form() {

  const [servicio, setServicio] = useState('');
  const [cargo, setCargo] = useState('');
  const [momento1, setMomento1] = useState('');
  const [momento2, setMomento2] = useState('');
  const [momento3, setMomento3] = useState('');
  const [momento4, setMomento4] = useState('');
  const [momento5, setMomento5] = useState('');

  const navigate = useNavigate();
  const date = new Date();
  const { user } = useUserAuth();
  const formCollection = collection(db, 'Higiene de manos')

const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc(formCollection, {
      date: date,
      user: user.email,
      servicio: servicio,
      cargo: cargo,
      momento1: momento1,
      momento2: momento2,
      momento3: momento3,
      momento4: momento4,
      momento5: momento5,
    })
  .then(()=> {
    Swal.fire(
      '¡Buen trabajo!',
      'El forumlario ha sido subido exitosamente',
      'success'
    )
    .then(()=> {
      navigate('/feed');
    })
  });
  }
  
  return (
    <>
    <Topbar
      user='Clínica Materno Infantil'
    />
    <div className="form">
      <h2>Higiene de manos</h2>
      <div className="form__container">
        <small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus iste.</small>
        <form action="" onSubmit={handleSubmit}>
          <br />
          <label>Servicio</label>
            <select className='form__input' onChange={(e)=>setServicio(e.target.value)}>
              <option hidden defaultValue>Elige el servicio</option>
              <option value='Hospitalización'>Hospitalización</option>
              <option value='UCI Adultos'>UCI Adultos</option>
              <option value='Consula externa'>Consulta externa</option>
              <option value='UCI neonatal'>UCI neonatal</option>
              <option value='Cirugia'>Cirugia</option>
            </select>
          <label>Cargo</label>
            <select className='form__input' onChange={(e)=>setCargo(e.target.value)}>
              <option hidden defaultValue>Elige el cargo</option>
              <option value='Médico especialista'>Médico especialista</option>
              <option value='Médico general'>Médico general</option>
              <option value='Enfermera(o)'>Enfermera(o)</option>
              <option value='Auxuliar'>Auxuliar</option>
              <option value='Estudiante'>Estudiante</option>
              <option value='Otras profesiones'>Otras profesiones</option>
            </select>
          <h3>Indicación</h3>
          <br />
          <label>Antes de entrar en contacto directo con el paciente.</label>
            <select className='form__input' onChange={(e)=>setMomento1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='Agua - jabón'>Agua - jabón</option>
              <option value='Alcohol/gel'>Alcohol/gel</option>
              <option value='Omisión'>Omisión</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label>Antes de realizar procedimiento invasivo.</label>
            <select className='form__input'  onChange={(e)=>setMomento2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='Agua - jabón'>Agua - jabón</option>
              <option value='Alcohol/gel'>Alcohol/gel</option>
              <option value='Omisión'>Omisión</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label>Después de contacto con fluidos corporales</label>
            <select className='form__input'  onChange={(e)=>setMomento3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
            <option value='Agua - jabón'>Agua - jabón</option>
              <option value='Alcohol/gel'>Alcohol/gel</option>
              <option value='Omisión'>Omisión</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label>Después del contacto con el paciente</label>
            <select className='form__input'  onChange={(e)=>setMomento4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
            <option value='Agua - jabón'>Agua - jabón</option>
              <option value='Alcohol/gel'>Alcohol/gel</option>
              <option value='Omisión'>Omisión</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <label>Despues del contacto con el entorno del paciente</label>
            <select className='form__input'  onChange={(e)=>setMomento5(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='Agua - jabón'>Agua - jabón</option>
              <option value='Alcohol/gel'>Alcohol/gel</option>
              <option value='Omisión'>Omisión</option>
              <option value='No aplica'>No aplica</option>
            </select>
            <div className="form__btn">
              <Button
                title='Enviar'
              />
            </div>
        </form>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Form