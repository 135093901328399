import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";
import { getFirestore } from '@firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDdmiIAAEm7QONKePw_36aQomBktI6OTPs",
  authDomain: "cmaterno-app.firebaseapp.com",
  projectId: "cmaterno-app",
  storageBucket: "cmaterno-app.appspot.com",
  messagingSenderId: "500390581695",
  appId: "1:500390581695:web:3bb6edd8cc650f10fdfd0a",
  measurementId: "G-3NTNFFQJSK"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
