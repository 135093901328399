import React from 'react';
import './Feed.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import Card from '../../components/card/Card';
import Navbar from '../../components/navbar/Navbar';
import { MdOutlineCleanHands, MdOutlineScience, MdLockOutline, MdOutlineCleaningServices } from "react-icons/md";
import { BsEmojiSmile} from "react-icons/bs";
import { useUserAuth } from '../../context/UserAuthContext';

function Feed() {

  const data = [
    {
     id: '1',
     path: '/higiene-manos',
     icon: <MdOutlineCleanHands/>,
     title: 'Higiene de manos',
     description: 'Estrategia multimodal',
    },
    {
     id: '2',
     path: '/ronda-seguridad',
     icon: <MdLockOutline/>,
     title: 'Ronda de seguridad',
     description: 'Estrategia multimodal',
    }
  ]
  const { user } = useUserAuth()
  return (
    <>
    <Topbar
      user='Clínica Materno Infantil'
    />
    <div className='feed'>
        <h2>¡Hola!, {user.email},</h2>
      <div className="feed__container">
          <h3>Elige la actividad</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                <Link to={item.path} style={{textDecoration: 'none'}} >
                  <Card
                      id={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                </Link>
                 
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Feed