import React from 'react';
import './Input.scss';

function Input({ label, type, placeholder, value, name, onChange, key}) {
  return (
  <>
    <div className='input__container'>
      <br />
      <p>{label}</p>
       <input 
          key={key}
          className='input'
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange} 
                  
        />
    </div>
  </>
  )
}

export default Input